<template>
	<div
		v-if="isBlockShown"
		:id="block.type + index"
		:class="'panel programm_schedule ' +
			(isHeaderIndent? ' mt-0 pt-1 ' : '') +
			(isFooterIndent? ' mb-0 pb-1 ' : '') +
			block.appearance"
	>
		<div
			class="container"
			:class="[
				isBrand? 'sk' : '',
			]"
		>
			<div class="panel_header">
				<h2>{{ block.block_title }}</h2>
			</div>

			<ul v-if="block.accordion !==null && block.accordion === 1" class="program_accordeon_list">
				<li
					v-for="(item, index) in block.items"
					:key="index"
					class="row d-flex align-self-center justify-content-start"
				>
					<div class="col-12 col-lg-3">
						<div class="module_data">
							<div v-if="'title' in item" class="module_title title">
								{{ item.title }}
							</div>
							<div v-if="item.custom_date !==null" class="description">
								<span>{{ item.custom_date }}</span>
							</div>
							<div v-else-if="item.date_start" class="description">
								<span>{{ item.date_start | moment("DD MMMM YYYY") }}</span> -
								<span>{{ item.date_end | moment("DD MMMM YYYY") }}</span>
							</div>
							<div v-if="item.location !==null" class="description mt-2" v-html="item.location" />
						</div>
					</div>

					<div class="col-12 col-lg-9">
						<div
							v-for="(sub_item, sub_index) in item.sub_items"
							:key="sub_index"
							class="module_item"
						>
							<div class="theme" />
							<div v-if="'name' in sub_item" class="title">
								{{ sub_item.name }}
							</div>
							<div class="description">
								<p v-if="'description' in sub_item">
									{{ sub_item.description }}
								</p>
							</div>
							<client-only>
								<b-collapse :id="'module-collapse-' + index">
									<div v-if="'answer' in sub_item" class="content description" v-html="sub_item.answer" />
								</b-collapse>
							</client-only>
						</div>
					</div>

					<div class="col-12">
						<div class="module_footer">
							<b-button
								v-b-toggle="'module-collapse-' + index"
								v-b-tooltip.hover
								variant="resize"
								class="icon-chevron"
								:title="$t('moreDetails')"
							/>
						</div>
					</div>
				</li>
			</ul>

			<!-- Вариант - не расхлоп -->
			<ul v-else class="program_list">
				<li
					v-for="(item, index) in block.items"
					:key="index"
					class="row d-flex align-self-center justify-content-start"
				>
					<div class="col-12 col-sm-12 col-lg-12">
						<div class="row schedule_data">
							<div class="col-12 col-md-4">
								<div class="module_data">
									<div v-if="'title' in item" class="module_title title">
										{{ item.title }}
									</div>
									<div v-if="item.custom_date !==null" class="description">
										<span>{{ item.custom_date }}</span>
									</div>
									<div v-else-if="item.date_start" class="description">
										<span>{{ item.date_start | moment("DD MMMM YYYY") }}</span> -
										<span>{{ item.date_end | moment("DD MMMM YYYY") }}</span>
									</div>
									<div
										v-if="item.location !==null"
										class="description mt-2"
										v-html="item.location"
									/>
								</div>
							</div>
							<div class="col-12 col-md-8">
								<div v-for="(sub_item, index) in item.sub_items" :key="index" class="module_item">
									<div v-if="'name' in sub_item" class="title">
										{{ sub_item.name }}
									</div>
									<div v-if="'answer' in sub_item" class="content" v-html="sub_item.answer" />
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>

			<div v-if="index === 0 && isFooterVisible" class="panel_footer">
				<div class="row">
					<div class="col-12">
						<nuxt-link to="#panel_form" class="btn btn-primary">
							{{ $t('registration-on') }} {{ fetchedData.type_titles.title_dative }}
						</nuxt-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';
import checkIsBlockShown from '~/mixins/checkIsBlockShown';
import '@/utils/moment';

export default {
	name: 'PanelProgramSchedule',
	mixins: [panel, checkIsBlockShown],
	props: {
		index: { type: Number, required: true },
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-schedule";
	.programm_schedule {
		// margin-top: 3rem;
		.panel_footer {
			margin-top: 1.5rem;
		}
		.title {
			margin-bottom: 0.5rem;
		}
		.schedule_list {
			border-bottom: 1px solid $body-color; // margin-bottom: 3rem;
		}
		.schedule_time {
			margin-bottom: 1rem;
			height: auto;
		}
	}
</style>

<i18n lang="yaml">
ru:
  moreDetails: "Подробнее"
  registration-on: "Регистрация на"
en:
  moreDetails: "More details"
  registration-on: "Registration for the"
</i18n>

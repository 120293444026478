import { render, staticRenderFns } from "./PanelProgramSchedule.vue?vue&type=template&id=478cc8cc&scoped=true"
import script from "./PanelProgramSchedule.vue?vue&type=script&lang=js"
export * from "./PanelProgramSchedule.vue?vue&type=script&lang=js"
import style0 from "./PanelProgramSchedule.vue?vue&type=style&index=0&id=478cc8cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478cc8cc",
  null
  
)

/* custom blocks */
import block0 from "./PanelProgramSchedule.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCollapse, BButton} from 'bootstrap-vue'
    installComponents(component, {BCollapse, BButton})
    


    import installDirectives from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBToggle, VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-toggle': VBToggle, 'b-tooltip': VBTooltip})
    

export default component.exports